<template>
    <v-container fluid class="department-map">
        <v-row class="flex-grow-0">
            <v-col>
                <h1>{{ $t('departments.tree') }}</h1>
            </v-col>
            <DepartmentsButtons></DepartmentsButtons>
        </v-row>
        <v-row>
            <v-col style="position: relative;">
                <v-card
                    class="not-assigned-persons"
                    :class="{'not-assigned-persons__drug-over': draggingPerson !== null && dragOverNotAssigned}"
                    elevation="4"
                    @drop="onDropToNotAssigned"
                    @dragend.prevent
                    @dragover.prevent
                    @dragenter="onDragEnterToNotAssigned($event)"
                    @dragleave="onDragLeaveFromNotAssigned($event)"
                    ref="notAssignedBlock"
                >
                    <div class="not-assigned-persons__overlay">
                        Удалить сотрудника из отдела
                    </div>
                    <v-badge
                        class="not-assigned-block__toggle"
                        :content="notAssignedPersons.length"
                        overlap
                        bordered
                    >
                        <v-card-title class="not-assigned-persons__title" @click="toggleNotAssignedBlock">
                            Не распределенные сотрудники
                        </v-card-title>
                    </v-badge>
                    <v-card-text class="not-assigned-persons__body" v-if="notAssignedBlock">
                        <div class="not-assigned-persons__inner">
                            <DepartmentPerson
                                class="department__person"
                                v-for="person in notAssignedPersons"
                                :key="person.id"
                                :person="person"
                                draggable="true"
                                @dragstart.stop="onPersonDragStart($event, person, null, 'member')"
                                @dragend.stop="onPersonDragEnd($event, person)"
                            />
                        </div>
                    </v-card-text>
                </v-card>
                <div
                    class="departments-tree__wrap"
                    ref="treeWrap"
                    @mousedown="onDragTreeStart"
                    @mousemove="onDragTree"
                    @mouseup.prevent="onDragTreeEnd"
                    @wheel.prevent="onWheel"
                >
                    <div
                        class="tf-tree tf-gap-lg"
                        ref="tree"
                        :style="{ transform: `translate(${translateX}px, ${translateY}px)` }"
                    >
                        <DepartmentTreeBrunch
                            class="departments-tree"
                            :items="departments"
                            :draggingPerson="draggingPerson"
                            :draggingDepartment="draggingDepartment"
                            :dragOverDepartment="dragOverDepartment"
                            :max-depth="3"
                            @onDepartmentHeadSet="onDepartmentHeadSet"
                            @onDepartmentDelete="delDepartment"
                            @onPersonDragStart="onPersonDragStart"
                            @onPersonDragEnd="onPersonDragEnd"
                            @onDropPerson="onDropPerson"
                            @onDragOverDepartment="onDragOverDepartment"
                            @onDepartmentDragStart="onDepartmentDragStart"
                            @onDepartmentDragEnd="onDepartmentDragEnd"
                            @onDropToDepartmentChildren="onDropToDepartmentChildren"
                            :style="{transform: `scale(${scale})`}"
                        ></DepartmentTreeBrunch>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {buildTree} from '@/plugins/functions';
import DepartmentTreeBrunch from "@/components/DepartmentTreeBrunch.vue";
import DepartmentsButtons from "@/components/DepartmentsButtons.vue";
import DepartmentPerson from "@/components/DepartmentPerson.vue";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "DepartmentsTree",

    components: {
        // Btn,
        DepartmentPerson,
        DepartmentTreeBrunch,
        DepartmentsButtons
    },

    data() {
        return {
            loading: false,
            myArray: [],
            draggingPerson: null,
            fromDepartment: null,
            draggingDepartment: false,
            departments: [],
            dragOverDepartment: null,
            dragOverNotAssigned: false,
            notAssignedPersons: [],

            treeIsDragging: false,
            startX: null,
            startY: null,
            offsetX: null,
            offsetY: null,
            scale: 1,
            minScale: 0.2,
            translateX: 0,
            translateY: 0,
            notAssignedBlock: false,
        }
    },

    async mounted() {
        await this.fetchDepartments();
        this.$nextTick(this.fitToScreen);
        this.fetchNotAssignedPersons();
    },

    methods: {
        fitToScreen() {
            const treeWrap = this.$refs.treeWrap;
            const tree = this.$refs.tree;
            if (tree && treeWrap) {
                const wrapRect = treeWrap.getBoundingClientRect();
                const treeRect = tree.getBoundingClientRect();

                this.translateX = (wrapRect.width - treeRect.width) / 2;
                this.translateY = (wrapRect.height - treeRect.height) / 2;

                if(treeRect.width > 0 && treeRect.height > 0) {
                    const scaleX = wrapRect.width / treeRect.width;
                    const scaleY = wrapRect.height / treeRect.height;
                    this.scale = Math.min(scaleX, scaleY) > this.minScale ? Math.min(scaleX, scaleY) : this.minScale;
                } else {
                    this.scale = 1;
                }
            }
        },

        onDepartmentDragStart(e, department) {
            e.dataTransfer.setData('department', JSON.stringify(department));
            this.draggingDepartment = true;
        },

        onDepartmentDragEnd(e, department) {
            this.draggingDepartment = false;
        },

        onDragOverDepartment(e, department) {
            this.dragOverDepartment = department;
        },

        onPersonDragStart(e, person, department, position) {
            this.draggingPerson = person;
            this.fromDepartment = department;

            e.dataTransfer.setData('person', JSON.stringify(person))
            e.dataTransfer.setData('fromDepartment', JSON.stringify(department))
            e.dataTransfer.setData('fromPosition', position)
        },

        onPersonDragEnd($event) {
            this.draggingPerson = null;
            this.draggingDepartment = false;
            this.dragOverDepartment = null;
            this.fromDepartment = null;
            this.dragOverNotAssigned = false;
        },

        async onDropPerson(e, person, fromDepartment, fromPosition, toDepartment, toPosition) {
            if (fromDepartment && fromPosition && toDepartment && toPosition) {
                if (fromDepartment.id === toDepartment.id && fromPosition === toPosition) {
                    return;
                }
            }

            // update from
            if (fromDepartment && fromDepartment.id !== 0) {
                let fromDepartmentData = {};
                if (fromPosition === 'head') {
                    fromDepartmentData.head_id = null;
                } else {
                    fromDepartmentData.members = fromDepartment.members.filter(member => member.id !== person.id).map(member => member.id);
                }

                this.loading = true;
                await this.$http.put(`admin/department/${fromDepartment.id}`, fromDepartmentData).then(async response => {
                    await this.fetchDepartments();
                    await this.fetchNotAssignedPersons();
                    this.$toastr.success(this.$t('departments.person_moved'));
                }).catch((err) => {
                    this.$toastr.error(this.$t('error_update_department'));
                    this.$toastr.error(this.$t(err.message));
                }).finally(() => {
                    this.loading = false;
                })
            }

            // update to
            if (toDepartment && toDepartment.id > 0) {
                let toDepartmentData = {};

                if (toPosition === 'head') {
                    toDepartmentData.head_id = person.id;
                } else {
                    toDepartmentData.members = toDepartment.members.map(member => member.id) ?? [];
                    toDepartmentData.members.push(person.id);
                }

                this.loading = true;
                await this.$http.put(`admin/department/${toDepartment.id}`, toDepartmentData).then(async response => {
                    await this.fetchDepartments();
                    await this.fetchNotAssignedPersons();
                    this.$toastr.success(this.$t('departments.person_moved'));
                }).catch((resp) => {
                    this.$toastr.error(this.$t('error_update_department'));

                    if (resp.body.message) {
                        this.$toastr.error(this.$t(resp.body.message));
                    }
                }).finally(() => {
                    this.loading = false;
                })
            }
        },

        onDropToDepartmentChildren(e, department, toDepartment) {
            if (department.id === 0)
                return;

            if (department.id === toDepartment.id)
                return;

            let departmentData = {
                parent_id: toDepartment.id > 0 ? toDepartment.id : null
            }

            this.loading = true;
            this.$http.put(`admin/department/${department.id}`, departmentData).then(response => {
                this.fetchDepartments();
            }).catch((resp) => {
                this.$toastr.error(this.$t('departments.error_update_department'));

                if (resp.body.message) {
                    this.$toastr.error(this.$t(resp.body.message));
                }
            }).finally(() => {
                this.loading = false;
            })
        },

        async fetchDepartments() {
            let { body } = await this.$http.get('admin/department');
            this.departments = buildTree(body.data, 0, 'id', 'parent_id', 'children');
            return this.departments;
        },

        fetchNotAssignedPersons() {
            this.$http.get('admin/admin', {
                params: {
                    'no-trashed': 1,
                    fltr: {
                        department_id: {
                            eq: '0'
                        }
                    },
                    perPage: 1000
                }
            })
                .then(response => {
                    this.notAssignedPersons = response.body.data;
                });
        },

        delDepartment(id) {
            if (confirm(this.$t('departments.prompt_delete'))) {
                this.$http.delete('admin/department/' + id)
                    .then(response => {
                        this.fetchDepartments();
                    });
            } else {
                return false;
            }
        },

        onDepartmentHeadSet(item, person) {
            this.fetchDepartments();
        },

        onDragTreeStart(e) {
            // если клик вне .department
            if (!e.target.closest(`.department`)) {
                this.treeIsDragging = true;
                this.startX = e.clientX - this.translateX;
                this.startY = e.clientY - this.translateY;
            }
        },

        onDragTree(e) {
            if (!this.treeIsDragging) {
                return;
            }

            if (this.treeIsDragging) {
                this.translateX = e.clientX - this.startX;
                this.translateY = e.clientY - this.startY;
            }
        },

        onDragTreeEnd(e) {
            this.treeIsDragging = false;
        },

        onWheel(event) {
            const scaleAmount = event.deltaY * -0.001;
            this.scale += scaleAmount;
            if (this.scale < 0.1) {
                this.scale = 0.1;
            } else if (this.scale > 3) {
                this.scale = 3;
            }
        },

        onDropToNotAssigned(e) {
            let person = JSON.parse(e.dataTransfer.getData('person'));
            let fromDepartment = JSON.parse(e.dataTransfer.getData('fromDepartment'));
            let fromPosition = e.dataTransfer.getData('fromPosition');

            this.onDropPerson(e, person, fromDepartment, fromPosition, null, null);
        },

        toggleNotAssignedBlock() {
            this.notAssignedBlock = !this.notAssignedBlock;
        },

        onDragEnterToNotAssigned(e) {
            if (this.fromDepartment) {
                this.dragOverNotAssigned = true;
                e.preventDefault();
            }
        },

        onDragLeaveFromNotAssigned(e) {
            if (!this.$refs.notAssignedBlock.$el.contains(e.relatedTarget)) {
                this.dragOverNotAssigned = false;
            }
        }
    }
}
</script>

<style lang="scss">
@import "../scss/treeflex.scss";

.department-map {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.loader__wrap {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.8);

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }
}

.departments-tree__wrap {
    cursor: move;
    max-width: 100%;
    scrollbar-width: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #eee;
    overflow: hidden;
    user-select: none;
}

.departments-tree {
    position: relative;
}

.not-assigned-persons__title {
    font-size: inherit;
}

.not-assigned-persons__body {
    overflow: auto;
}

.not-assigned-persons__inner {
    //position: absolute;
    //display: flex;
    //flex-direction: column;
    //right: 0;
    //top: 0;
    //bottom: 0;
    //left: 0;
}

.not-assigned-persons__overlay {
    display: none;
    position: relative;
}

$borderColor: #ccc;
.not-assigned-persons__drug-over {
    .not-assigned-persons__overlay {
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.9);
        border: 1px dashed $borderColor;
        z-index: 1;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
    }
}

.not-assigned-persons {
    position: absolute;
    top: $grid-gutter;
    right: $grid-gutter;
    z-index: 10;
    max-width: 270px;
    max-height: calc(100% - #{$grid-gutter * 2});
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;

    &.on-drug {
        border-color: red;
        border-radius: 8px;
    }

    .department__person {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        align-items: center;
        margin-right: $grid-gutter;
        margin-bottom: $grid-gutter / 2;
    }

    .department__person-avatar {
        margin-right: 10px;
        margin-bottom: 0;
    }

    .department__person-name {
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .department__person-position {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: block;
    }
}
</style>

<script>
export default {
    name: "DepartmentsButtons"
}
</script>

<template>
    <v-col v-if="can(['administrator','director', 'hr_manager'])" class="text-right">
        <v-btn text :to="{path: '/department', query: {filter: {parent_id: {eq: 0}}}}" class="infinity_button mr-5" color="primary" :exact="true">
            {{ $t('departments.list_view') }}
        </v-btn>
        <v-btn text :to="{path: '/department/tree', query: {}}" class="infinity_button mr-5" color="primary" :exact="true">
            {{ $t('departments.tree_view') }}
        </v-btn>
        <v-btn :to="{name: 'department.create'}" class="infinity_button" color="primary">
            {{ $t('create') }}
        </v-btn>
    </v-col>
</template>

<style>

</style>

<script>
import DepartmentPerson from "@/components/DepartmentPerson.vue";

export default {
    name: "DepartmentTreeBrunch",
    components: {
        DepartmentPerson
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        depth: {
            type: Number,
            default: 1
        },
        draggingPerson: {
            type: Object,
            default: null
        },
        draggingDepartment: {
            type: Boolean,
            default: false
        },
        dragOverDepartment: {
            type: Object,
            default: null
        },
        maxDepth: {
            type: Number,
            default: 3
        },
        closeble: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            dragenter: false,
            draggablePerson: null,
            visibleChildren: true
        }
    },
    methods: {
        edit(id) {
            this.$router.push({
                name: 'department.edit',
                params: {
                    id: id
                }
            });
        },

        delDepartment(id) {
            this.$emit('onDepartmentDelete', id);
        },

        addChildDepartment(item) {
            this.$router.push({
                name: 'department.create',
                query: {
                    parent_id: item.id
                }
            });
        },

        onPersonDragStart(e, person, department, position) {
            this.$emit('onPersonDragStart', e, person, department, position);
        },

        onPersonDragEnd(e, head) {
            this.$emit('onPersonDragEnd', e);
        },

        onDepartmentHeadSet(item, person) {
            this.$emit('onDepartmentHeadSet', item, person);
        },

        onDragOverDepartment(e, department) {
            this.$emit('onDragOverDepartment', e, department);
        },

        onDepartmentDragStart(e, department) {
            this.$emit('onDepartmentDragStart', e, department);
        },

        onDepartmentDragEnd(e, department) {
            this.$emit('onDepartmentDragEnd', e, department);
        },

        onDropPersonInner(e, toDepartment, toPosition) {
            let person = e.dataTransfer.getData('person');

            if (person) {
                person = JSON.parse(person);
                let fromDepartment = JSON.parse(e.dataTransfer.getData('fromDepartment'));
                let fromPosition = e.dataTransfer.getData('fromPosition');

                this.$emit('onDropPerson', e, person, fromDepartment, fromPosition, toDepartment, toPosition);
            }
        },

        onDropToDepartmentChildrenInner(e, toDepartment) {
            let department = e.dataTransfer.getData('department');

            if (department) {
                department = JSON.parse(department);

                this.onDropToDepartmentChildren(e, department, toDepartment);
            }
        },

        onDropToDepartmentChildren(e, department, toDepartment) {
            this.$emit('onDropToDepartmentChildren', e, department, toDepartment);
        },

        onDropPerson(e, person, fromDepartment, fromPosition, toDepartment, toPosition) {
            this.$emit('onDropPerson', e, person, fromDepartment, fromPosition, toDepartment, toPosition);
        },

        showChildren(department) {
            this.visibleChildren = department.id;
        },

        hideChildren() {
            this.visibleChildren = null;
        }
    }
}
</script>

<template>
    <ul :data-depth="depth">
        <li :class="{'department--head': department.id === 0, 'dropable': dragenter, 'active': visibleChildren === department.id, 'closable': closeble}" v-for="(department, k) in items" :key="k">
            <v-card class="department tf-nc" :class="{
                'drag-over-person': dragOverDepartment && dragOverDepartment.id === department.id && draggingPerson !== null,
                'drag-over-department': dragOverDepartment && dragOverDepartment.id === department.id && draggingDepartment
            }"
                    @dragenter.prevent
                    @dragover.prevent="onDragOverDepartment($event, department)"
                    @dragstart="onDepartmentDragStart($event, department)"
                    @dragend="onDepartmentDragEnd($event, department)"
                    @drop.prevent="onDropToDepartmentChildrenInner($event, department)"

                    :draggable="department.id !== 0"
            >
                <div class="department__overlay department__children">
                    {{ $t('departments.add_subdepartment_to_department') }}
                </div>
                <v-card-title class="department__title">
                    <span>{{ department.name }}</span>
                    <div v-if="department.id !== 0">
                        <v-icon small @click="addChildDepartment(department)" :title="$t('departments.add_child_department')">mdi-plus-circle-multiple</v-icon>
                        <v-icon small @click="edit(department.id)" :title="$t('edit')">mdi-pencil-outline</v-icon>
                        <v-icon small @click="delDepartment(department.id)" :title="$t('delete')">mdi-trash-can-outline</v-icon>
                    </div>
                </v-card-title>
                <v-card-text class="department__content">
                    <div v-if="department.id !== 0" class="department__head-wap" :class="{'on-drug': draggingPerson !== null}" @dragover.prevent @dragenter.prevent @drop="onDropPersonInner($event, department, 'head')">
                        <div class="department__head-overlay department__overlay">
                            {{ $t('departments.set_person_to_department_head') }}
                        </div>
                        <DepartmentPerson v-if="department.head" :person="department.head" class="department__head" draggable="true"
                                          @dragend.stop="onPersonDragEnd($event, department.head)"
                                          @dragstart.stop="onPersonDragStart($event, department.head, department, 'head')"
                        ></DepartmentPerson>
                        <DepartmentPerson v-else class="department__head"></DepartmentPerson>
                    </div>

                    <div
                        class="department__members-wrap"
                        :class="{'on-drug': draggingPerson !== null}"
                        @dragend.prevent @dragover.prevent
                        @drop="onDropPersonInner($event, department, 'member')"
                    >
                        <div class="department__overlay department__members-overlay">
                            {{ $t('departments.add_person_to_members') }}
                        </div>
                        <div class="department__members-overflow">
                            <div class="department__members" :class="{'root': department.id === 0}">
                                <DepartmentPerson class="department__member" v-for="(member, index) in department.members" :key="index" :person="member" draggable="true"
                                                  @dragstart.stop="onPersonDragStart($event, member, department, 'member')"
                                                  @dragend.stop="onPersonDragEnd($event, member)"
                                ></DepartmentPerson>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            <div class="tf-children" v-if="department.children && department.children.length > 0">
                <DepartmentTreeBrunch
                    :draggingPerson="draggingPerson"
                    :items="department.children"
                    :depth="depth + 1"
                    :dragOverDepartment="dragOverDepartment"
                    :draggingDepartment="draggingDepartment"
                    :closeble="visibleChildren === department.id"
                    @onDepartmentDelete="delDepartment"
                    @onDepartmentHeadSet="onDepartmentHeadSet"
                    @onPersonDragStart="onPersonDragStart"
                    @onPersonDragEnd="onPersonDragEnd"
                    @onDropPerson="onDropPerson"
                    @onDragOverDepartment="onDragOverDepartment"
                    @onDepartmentDragStart="onDepartmentDragStart"
                    @onDepartmentDragEnd="onDepartmentDragEnd"
                    @onDropToDepartmentChildren="onDropToDepartmentChildren"
                    @close="hideChildren"
                />
            </div>
        </li>
    </ul>
</template>

<style lang="scss">
$borderColor: #ccc;
$avatarSize: 36px;
$dashWidth: 5px;
$personMinHeight: 50px;

.dropable {
    background-color: $borderColor;
}

.department {
    min-width: 200px;
    cursor: auto;

    &.tf-nc {
        display: flex !important;
        flex-direction: column;
    }

    &.drag-over-person {
        .department__members-overlay {
            display: flex;
        }

        .department__head-overlay {
            display: flex;
        }
    }

    &.drag-over-department {
        .department__children {
            display: flex;
        }
    }
}

.department__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.department__close {
    &.v-icon {
        position: absolute;
        right: 5px;
        top: 5px;
        color: #fff;
        z-index: 10;
    }
}

.department__title {
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
    flex-wrap: nowrap;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.on-drug {
    border-color: $borderColor;
    border-radius: 8px;
}

.department__head-wap {
    position: relative;
    border: 1px solid transparent;
}

.department__overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
    border-radius: 8px;
    cursor: pointer;
    border: 1px dashed $borderColor;
    color: $borderColor;
    font-size: 14px;
}

.department__members-overflow {
    max-height: 120px;
    overflow-y: auto;
    position: relative;
}

.department__members-wrap {
    position: relative;
    flex-grow: 1;
}

.department__members {
    display: flex;
    flex-direction: column;
    min-height: $personMinHeight;
    border: 1px solid transparent;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 1px;
        background-color: $borderColor;
        position: absolute;
        z-index: 10;
        top: -10px;
        bottom: $personMinHeight / 2;
        left: $avatarSize / 2;
    }

    &:empty {
        &:before {
            display: none;
        }
    }

    &.root {
        &:before {
            content: none;
        }
    }
}

.department__member {
    min-height: $personMinHeight;
    position: relative;
    padding-left: $dashWidth;
    margin-left: $avatarSize / 2;
    margin-bottom: 0;

    &:last-child {
        margin-bottom: 0;
    }

    &:before {
        content: "";
        width: $dashWidth;
        height: 1px;
        background-color: $borderColor;
        display: block;
        position: absolute;
        left: 0;
        z-index: 10;
        top: 50%;
    }
}

.root {
    .department__member {
        margin-left: 0;
        padding-left: 0;

        &:before {
            content: none;
        }
    }
}

.department--head {
    .tf-nc {
        max-width: 280px;
    }
}
</style>

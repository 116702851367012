<script>
export default {
    name: "DepartmentPerson",
    props: {
        person: {
            type: Object,
            required: false,
            default: function () {
                return {
                    name: this.$t('departments.head_not_set'),
                    position: null,
                    photo: null
                }
            }
        }
    }
}
</script>

<template>
    <div class="department__person" @dragend="$emit('dragend', $event)" @dragstart="$emit('dragstart', $event)">
        <v-avatar class="department__person-avatar border_blue" size="36">
            <img v-if="person.photo && person.photo !== '/img/avatar.png'" :title="person.name" class="avatar_border avatar_border_prime" :src="person.photo" :alt="person.name" draggable="false">
            <img v-else :title="person.name" class="avatar_border" src="/img/avatar.svg" :alt="person.name" draggable="false">
        </v-avatar>
        <div class="department__person-desc">
            <div class="department__person-name">{{ person.name }}</div>
            <span class="department__person-position" v-if="person.position">{{ person.position }}</span>
        </div>
    </div>
</template>

<style scoped lang="scss">
.department__person {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: center;
}

.department__person-avatar {
    margin-right: 10px;
    margin-bottom: 0;
}

.department__person-name {
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-y: hidden;
}

.department__person-position {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
}
</style>
